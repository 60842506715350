import React, { useState } from "react";
import { Menu, Drawer } from "antd";
import { CustomerServiceOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";

interface ChildProps {
  scrollTo: (id: string) => void; // 假设这个方法不接受参数且没有返回值
  setShowKefuModal: any
}

const ResponsiveNavBar: React.FC<ChildProps> = ({ scrollTo,setShowKefuModal }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const scrollToElement = (id: string) => {
    scrollTo(id);
  };

  // Drawer的自定义title
  const drawerTitle = (
    <div className={styles.drawerTitle}>
      <img
        src={require("./imgs/logoBig.png")}
        alt="Drawer Header"
        className={styles.drawerImage}
      />
      <div className={styles.drawerTextContent}>
        <div className={styles.drawerTitleText}>bkkwo能量</div>
        <div className={styles.drawerSubtitleText}>便捷省钱服务平台</div>
      </div>
    </div>
  );

  return (
    <div className={styles.responsiveNavBar}>
      <div className={styles.logoAndText}>
        <img
          src={require("./imgs/logo.png")}
          alt="logo"
          className={styles.iconStyle}
          onClick={toggleDrawer}
        />
        <span>bkkwo能量</span>
      </div>
      <div className={styles.menuContent}>
        <img
          src={require("./imgs/menu.png")}
          alt="Menu"
          className={styles.menuButton}
          onClick={toggleDrawer}
        />
        <Menu mode="horizontal" className={styles.horizontalMenu}>
          <Menu.Item key="1" onClick={() => scrollToElement("nengliang")}>
            兑换能量
          </Menu.Item>
          <Menu.Item key="2" onClick={() => scrollToElement("shuoming")}>
            操作说明
          </Menu.Item>
          <Menu.Item key="3" onClick={() => scrollToElement("pingtai")}>
            平台介绍
          </Menu.Item>
          <Menu.Item key="4" onClick={() => scrollToElement("wenti")}>
            常见问题
          </Menu.Item>
          <Menu.Item key="5" onClick={()=> {
            setShowKefuModal && setShowKefuModal(true);
          }}>
            <CustomerServiceOutlined />
            在线客服
          </Menu.Item>
        </Menu>
      </div>
      <Drawer
        title={drawerTitle}
        placement="right"
        onClose={toggleDrawer}
        closable={false}
        open={isOpen}
        className={styles.drawerContainer}
        width={220}
      >
        <Menu mode="vertical" className={styles.verticalMenu}>
          <Menu.Item key="1" onClick={() => {scrollToElement("nengliang");toggleDrawer();}}>
            兑换能量
          </Menu.Item>
          <Menu.Item key="2" onClick={() => {scrollToElement("shuoming");toggleDrawer();}}>
            操作说明
          </Menu.Item>
          <Menu.Item key="3" onClick={() => {scrollToElement("pingtai");toggleDrawer();}}>
            平台介绍
          </Menu.Item>
          <Menu.Item key="4" onClick={() => {scrollToElement("wenti");toggleDrawer();}}>
            常见问题
          </Menu.Item>
          <Menu.Item key="5" onClick={()=> {
            toggleDrawer();
            setShowKefuModal && setShowKefuModal(true);
          }}>
            <CustomerServiceOutlined />
            在线客服
          </Menu.Item>
        </Menu>
      </Drawer>
    </div>
  );
};

export default ResponsiveNavBar;
