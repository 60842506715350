import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import "antd/dist/reset.css";
import ResponsiveNavBar from "./components/navBar";
import TopAdvertiseView from "./components/topAdvertiseView";
import EnergyPage2 from "./components/energyView2";
import InstructionsView from "./components/instructionsView";
import PlatformIntroView from "./components/platformIntroView";
import QuestionView from "./components/questionView";
import FootView from "./components/footView";
import { message } from "antd";
import { initEnergyNumber } from "./tools/help";

function App() {
  const [showKefuModal, setShowKefuModal] = useState(false);
  const [showToTopButton, setShowToTopButton] = useState(false);

  useEffect(() => {
    initEnergyNumber(); // 初始化能量数

    const handleScroll = () => {
      const currentPosition = window.document.body.scrollTop;
      // 根据滚动位置决定是否显示返回顶部按钮
      setShowToTopButton(currentPosition > 300); // 假设超过300px显示按钮
    };

    // 监听滚动事件
    window.addEventListener("scroll", handleScroll, true);
    // 组件卸载时移除事件监听
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleKefuCopy = () => {
    navigator.clipboard.writeText("jam09124");
    message.info(`已复制: ${"jam09124"}`);
  };

  const toKefuUrl = () => {
    const kefuUrl = "https://t.me/jam09124";
    window.open(kefuUrl, "_blank");
  };

  return (
    <div className={styles.App}>
      <ResponsiveNavBar
        scrollTo={scrollTo}
        setShowKefuModal={setShowKefuModal}
      />
      <TopAdvertiseView />
      <div className={styles.firstContent}>
        <div className={styles.contentItem}>
          <EnergyPage2 />
        </div>
        <div className={styles.contentItem} id={"pingtai"}>
          <PlatformIntroView />
        </div>
      </div>
      <div className={styles.bottomDivider} id={"shuoming"}></div>{" "}
      {/* 分割线 */}
      <InstructionsView />
      <div className={styles.bottomDivider} id={"wenti"}></div> {/* 分割线 */}
      <QuestionView />
      <FootView />
      {showKefuModal && (
        <div
          className={styles.modalOverlay}
          onClick={() => setShowKefuModal(false)}
        >
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.modalHeader}>
              <span>在线客服</span>
              <img
                src={require("./imgs/close.png")}
                alt="Close"
                className={styles.closeButton}
                onClick={() => setShowKefuModal(false)}
              />
            </div>
            <span className={styles.modalTips}>
              有碰到任何问题，欢迎咨询在线客服
            </span>
            <img
              src={require("./imgs/sendmsg.png")}
              alt="sendmsg"
              className={styles.kefuImage}
            />
            <span className={styles.kefuName} onClick={toKefuUrl}>
              @jam09124
            </span>
            <button onClick={handleKefuCopy} className={styles.copyButton}>
              <i className={styles.copyIcon}></i>
              <span>点击复制</span>
            </button>
          </div>
        </div>
      )}
      <img
        src={require("./imgs/kefu.png")}
        alt="kefu"
        className={styles.kefuFlotButton}
        onClick={() => setShowKefuModal(true)}
      />
      {showToTopButton && (
        <img
          src={require("./imgs/toTop.png")}
          alt="kefu"
          className={styles.toTopFlotButton}
          onClick={() => scrollTo("nengliang")}
        />
      )}
    </div>
  );
}

export default App;
