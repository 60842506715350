import React from "react";
import styles from "./index.module.scss"; // 引入SCSS样式文件

const QuickBuyLayout = () => {
  return (
    <div>
      <div className={styles.imageContainer} id={"nengliang"}>
        {/* 假设你的图片路径是 '/path/to/your/image.jpg' */}
        {/* <img
          src={require("./imgs/topbg.png")}
          alt="大客户专属批量兑换通道"
          className={styles.responsiveImage}
        /> */}
        <div className={styles.textOverlay}>
          <h1>BKKWO (tron/trx) 能量购买</h1>
          <p>为您解决"trx交易手续费不足"的问题</p>
        </div>
        {/* <div className={styles.marqueeContainer}>
          <div className={styles.marquee}>
            <span className={styles.tip}>温馨提示:</span>{" "}
            因为币价飞涨，能量池供应紧缺，购买时请注意余额！
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default QuickBuyLayout;
