import energyStore from "../store/energyStore";

// 生成随机8位数的函数
export function generateRandomNumber() {
  const firstDigit = Math.floor(Math.random() * 4) + 5; // 生成2到4之间的首位数字
  const otherDigits = Math.floor(Math.random() * 10000000); // 生成剩余的7位数字
  return firstDigit * 10000000 + otherDigits;
}

// 保存随机数到localStorage的函数
function saveNumberToLocal() {
  let currentNumber = localStorage.getItem("randomNumber");

  // 如果本地已经存在随机数，且该数是8位数，直接返回；否则，生成新的随机数并保存
  if (currentNumber && currentNumber.length === 8) {
    return;
  } else {
    let newNumber = generateRandomNumber();
    localStorage.setItem("randomNumber", newNumber.toString());
  }
}

// 每间隔3分钟，用本地保存的数随机减去32000~65000之间的一个数然后再保存到本地的函数
function updateNumberPeriodically() {
  setInterval(() => {
    let currentNumber = parseInt(
      localStorage.getItem("randomNumber") || generateRandomNumber().toString(),
      10
    );
    let randomNumberToSubtract = Math.floor(
      32000 + Math.random() * (65000 - 32000)
    );
    let updatedNumber = currentNumber - randomNumberToSubtract;

    // 确保更新后的数不小于7位数，如果小于7位数，则重新生成8位数
    if (updatedNumber < 1000000) {
      updatedNumber = generateRandomNumber();
    }

    localStorage.setItem("randomNumber", updatedNumber.toString());
    energyStore.updateNumber(updatedNumber);
  }, 180000); // 180000毫秒 = 3分钟
}

// 初始化函数：保存随机数到本地并设置定时更新
export function initEnergyNumber() {
  //   saveNumberToLocal(); // 首次运行，保存随机数到本地
  updateNumberPeriodically(); // 开始定时更新逻辑
}
