// store.js
import { makeAutoObservable } from "mobx";
import { generateRandomNumber } from "../tools/help";

class NumberStore {
  number = generateRandomNumber(); // 初始值

  constructor() {
    makeAutoObservable(this);
    this.loadNumber(); // 构造函数中加载初始值
  }

  // 从localStorage加载数字
  loadNumber() {
    const storedNumber = localStorage.getItem("randomNumber");
    this.number = storedNumber
      ? parseInt(storedNumber, 10)
      : generateRandomNumber();
  }

  // 更新数字
  updateNumber(difference: number) {
    this.number -= difference;
    if (this.number < 1000000) {
      this.number = generateRandomNumber();
    }
  }
}

const numberStore = new NumberStore();
export default numberStore;
