import React from 'react';
import styles from './index.module.scss'; // 确保路径正确

const FooterLayout = () => {
  return (
    <div className={styles.footer}>
      bkkwo能量 便捷省钱服务平台
    </div>
  );
};

export default FooterLayout;
