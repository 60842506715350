import React from "react";
import styles from "./index.module.scss"; // 确保路径正确

const InstructionsLayout = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>操作说明</div>
      <ul className={styles.instructionsList}>
        <li>
          <span>接收能量的地址为付款方。</span>
        </li>
        <li>
          <span>
            下单购买tron/trx能量，仅需数秒自动到账。请务必确保转账TRX数量正确。
          </span>
        </li>
        <li>
          <span>
            支持API方式批量购买tron/trx能量，为您的业务场景带来无限可能。请联系客服对接。
          </span>
        </li>
        <li>
          <span>
            当前转账一次USDT，需要消耗32000能量（对方无U时能量加倍64000）。
          </span>
        </li>
      </ul>
    </div>
  );
};

export default InstructionsLayout;
