import React from 'react';
import styles from './index.module.scss'; // 确保路径正确

const FAQLayout = () => {
  const faqs = [
    { title: '如何购买次数', description: '请在本页站头部中填写购买信息，然后根据提示向平台账户转入对应的TRX/USDT即可。' },
    { title: '支付成功后，能量什么时候到账？', description: '付款完成后，正常情况下能量会在6秒内到账。由于广播延迟等原因，极少数情况，会存在30到60秒的延迟。' },
    { title: '同一接收地址同时可以几笔订单？', description: '同一地址也可以多笔购买，不限制使用次数。' },
    { title: '订单可以撤回吗？', description: '当您向平台账户支付费用后，订单不支持撤回。' },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.title}>FAQ常见问题</div>
      {faqs.map((faq, index) => (
        <div key={index} className={styles.faqItem}>
          <div className={styles.faqTitle}>{faq.title}</div>
          <div className={styles.faqDescription}>{faq.description}</div>
        </div>
      ))}
    </div>
  );
};

export default FAQLayout;