import React, { useState } from "react";
import { Input, Button, Form, Radio, message, Popover, Divider } from "antd";
import styles from "./index.module.scss"; // 引入SCSS样式文件
import QRCode from "qrcode.react";
import { observer } from "mobx-react";
import numberStore from "../../store/energyStore"; // 引入store
import { click } from "@testing-library/user-event/dist/click";

const QuickBuyLayout = () => {
  const [inputValue, setInputValue] = useState("32000");
  const [error, setError] = useState("");
  const [timeUnit, setTimeUnit] = useState("1H");
  const [showQRCode, setShowQRCode] = useState(false);
  const [transferMinNum, setTransferMinNum] = useState(0);
  const [transferMaxNum, setTransferMaxNum] = useState(1);
  const energyMinValue = 32000;
  const energyMidValue = 64000;
  const energyMaxValue = 1000000;
  const payAddress = "TFUerBbGCjXCW6FnhD1iaVYVHcrB1zLUdk";
  // trx 对应时长需消耗的数量  数组中第一个是 最小消耗，第二个是最大消耗
  const trxCosts = { hour: [4.4, 8.8], day: [7.7, 15.4] };
  const [cost, setCost] = useState<number>(trxCosts.hour[0]);
  // 时长选项
  const trxTimes = [
    { time: "1H", decs: "1时" },
    { time: "1D", decs: "1天" },
  ];

  const handleClose = () => {
    setShowQRCode(false);
  };

  // 处理输入变化
  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
    if (value && parseInt(value, 10) < energyMinValue) {
      setError(`最小租用额度${energyMinValue}`);
    } else {
      setError("");
    }
  };

  // 快捷按钮改变输入值
  const handleQuickInput = (value: any) => {
    setInputValue(value.toString());
    setError("");
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(payAddress);
    message.info(`已复制: ${payAddress}`);
  };

  return (
    <div className={styles.energyContent2}>
      <div className={styles.energyTitle}>
        <div className={styles.titleTxt}>快捷购买波场能量</div>
        <div className={styles.tipsTxt}>
          <Popover
            placement="topLeft"
            content={
              <div>
                <span>
                  对方有U，转账燃烧13TRX，租用{energyMinValue}能量后无需再燃烧
                </span>
                <br />
                <span>
                  对方无U，转账燃烧27TRX，租用{energyMidValue}能量后无需再燃烧
                </span>
              </div>
            }
          >
            <>
              <i className={styles.tipsIcon}></i>
              <span className={styles.tipName}>如何估算</span>
            </>
          </Popover>
        </div>
      </div>
      <div className={styles.energyInput}>
        <Form.Item validateStatus={error ? "error" : ""} help={error || ""}>
          <Input
            type="number"
            placeholder={`最少${energyMinValue}`}
            value={inputValue}
            onChange={handleInputChange}
            style={error ? { borderColor: "red" } : {}}
            disabled={true}
          ></Input>
          <span className={styles.inputTip}>
            能量 <Divider type="vertical" className={styles.lineVertical} />{" "}
          </span>
        </Form.Item>
        <div className={`${styles.energyRightButtons}`}>
          <span
            onClick={() => {
              handleQuickInput(energyMinValue);
              setTransferMinNum(0);
              setTransferMaxNum(1);
              const timeCost =
                timeUnit === trxTimes[0].time
                  ? trxCosts.hour[0]
                  : trxCosts.day[0];
              setCost(timeCost);
            }}
            className={`${styles.energyButton} ${
              inputValue === energyMinValue.toString()
                ? styles.energyButtonActive
                : ""
            }`}
          >
            {energyMinValue}
          </span>
          <span
            className={`${styles.energyButton} ${
              inputValue === energyMidValue.toString()
                ? styles.energyButtonActive
                : ""
            }`}
            onClick={() => {
              handleQuickInput(energyMidValue);
              setTransferMinNum(1);
              setTransferMaxNum(2);
              const timeCost =
                timeUnit === trxTimes[0].time
                  ? trxCosts.hour[1]
                  : trxCosts.day[1];
              setCost(timeCost);
            }}
          >
            {energyMidValue}
          </span>
        </div>
      </div>
      <div className={styles.part2Container}>
        {/* <div className={styles.dayAbleContent}>
          <span>
            每天约可转账{transferMinNum} ~ {transferMaxNum}笔
          </span>
        </div> */}
        <div className={styles.taxContent}>
          <span className={styles.timeTitle}>时长</span>
          <Radio.Group
            value={timeUnit}
            onChange={(e) => {
              const timeValue = e.target.value;
              setTimeUnit(timeValue);
              let timeCost = trxCosts.hour[0];
              const inputNum = parseInt(inputValue, 10);
              if (timeValue === "1H") {
                if (inputNum >= energyMinValue && inputNum < energyMidValue) {
                  timeCost = trxCosts.hour[0];
                } else {
                  timeCost = trxCosts.hour[1];
                }
              } else {
                if (inputNum >= energyMinValue && inputNum < energyMidValue) {
                  timeCost = trxCosts.day[0];
                } else {
                  timeCost = trxCosts.day[1];
                }
              }
              setCost(timeCost);
            }}
            className={styles.toggleButton}
          >
            <Radio.Button
              value={trxTimes[0].time}
              className={timeUnit === trxTimes[0].time ? styles.selected : ""}
            >
              {trxTimes[0].decs}
            </Radio.Button>
            <Radio.Button
              value={trxTimes[1].time}
              className={timeUnit === trxTimes[1].time ? styles.selected : ""}
            >
              {trxTimes[1].decs}
            </Radio.Button>
          </Radio.Group>
        </div>
        <div className={styles.costEstimate}>
          费用预估：<span>{cost}TRX</span>
        </div>
        <Button
          className={styles.buyNowButton}
          onClick={() => {
            if (inputValue === "") {
              message.error("请输入购买能量");
              return;
            }
            if (parseInt(inputValue, 10) < energyMinValue) {
              message.error(`最少租用额度${energyMinValue}`);
              return;
            }
            if (parseInt(inputValue, 10) > energyMaxValue) {
              message.error(`最多租用额度${energyMaxValue}`);
              return;
            }
            setShowQRCode(true);
          }}
        >
          立即购买
        </Button>
        <div className={styles.ableEnergy}>
          <span>
            平台可用能量：
            <span className={styles.costNum}>{numberStore.number}</span>
          </span>
        </div>
      </div>
      {showQRCode && (
        <div className={styles.modalOverlay} onClick={handleClose}>
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.modalHeader}>
              <span>
                请向以下地址转账 <span className={styles.costNum}>{cost}</span>{" "}
                TRX
              </span>
            </div>
            <div className={styles.getEnergyInfo}>
              <span>
                以获得能量：{inputValue}，时长：{timeUnit}
              </span>
            </div>
            <div className={styles.payAddress}>
              <span>{payAddress}</span>
              <i className={styles.copyIcon} onClick={() => handleCopy()}></i>
            </div>
            <div>
              <QRCode
                value={payAddress}
                renderAs="canvas"
                className={styles.qrCode}
                size={128}
              />
            </div>
            <div className={styles.payTips}>
              <span>您当前正为自己购买能量，付款后能量将委托到付款地址</span>
            </div>
            <button onClick={handleClose} className={styles.saveButton}>
              我明白了
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(QuickBuyLayout);
