import React from "react";
import styles from "./index.module.scss"; // 确保路径正确

const PlatformLayout = () => {
  const descTips = [
    { desc: "解决 TRX 手续费过高问题" },
    { desc: "快捷方便，只需转账即可兑换能量" },
    { desc: "适用USDT、合约转账、部署合约等" },
    { desc: "Tron 波场用户必备省钱专家" },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>bkkwo (tron/trx) 能量一键购买</h2>
      </div>
      <div className={styles.threeColumns}>
        <div className={styles.column}>
          <img src={require("./imgs/economy.png")} alt="Feature 1" />
          <p>经济</p>
        </div>
        <div className={styles.column}>
          <img src={require("./imgs/safe.png")} alt="Feature 2" />
          <p>安全</p>
        </div>
        <div className={styles.column}>
          <img src={require("./imgs/highspeed.png")} alt="Feature 3" />
          <p>高效</p>
        </div>
      </div>
      <div className={styles.textRow}>
        bkkwo能量
        平台是波场生态中的能量兑换平台。我们的宗旨是为波场交易者降低能量消耗所需TRX费用。兑换流程简单快捷，价格优惠，24小时为您提供更安全且高效的能量兑换服务。
      </div>
      {descTips.map((_, index) => (
        <div key={index} className={styles.imageTextRow}>
          <img
            src={require("./imgs/right_arrow.png")}
            alt={`Detail ${index + 1}`}
          />
          <span>{_.desc}</span>
        </div>
      ))}
    </div>
  );
};

export default PlatformLayout;
